import React, { memo } from 'react';
import ImageGallery from 'react-image-gallery';
import { Image } from 'react-shimmer';

import '../image-gallery.scss';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../componentUI/constants';
import { Text } from '../../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../../componentUI/simple/Title';
import { SkeletonBasic } from '../../../componentUI/skeleton';
import { useMatchMedia } from '../../../hooks';

import { useLocale } from '../locale';
import { WrapperSlideStyled, WrapperTextContentStyled } from './styled';

import { LeftNav, RightNav } from '../CustomNavBtn';
import { Props } from './types';

const Gallery = memo(({ rightNavRef, setIsLastSlide, onClose }: Props) => {
  const { isMobile } = useMatchMedia();
  const { SLIDES } = useLocale();

  const numberLastSlide = SLIDES.length - 1;

  const images = SLIDES.map(({ slideDesktop, slideMobile, titleText, descriptionText }) => {
    return {
      original: `${isMobile ? slideMobile : slideDesktop}`,
      renderItem: () => {
        return (
          <WrapperSlideStyled>
            {/*
	// @ts-ignore */}
            <Image
              src={isMobile ? slideMobile : slideDesktop}
              fallback={
                <SkeletonBasic
                  width={isMobile ? '340px' : '506px'}
                  height={isMobile ? '382px' : '329px'}
                  bgColor={COLOR_MAP.controls.skeleton.content2}
                />
              }
            />
            <WrapperTextContentStyled>
              <Title style={{ whiteSpace: 'normal' }} size={TITLE_SIZE.h6} tag={TAG_NAME.h6}>
                {titleText}
              </Title>

              <Text style={{ whiteSpace: 'normal' }} colorText={COLOR_MAP.text.darkGrey}>
                {descriptionText}
              </Text>
            </WrapperTextContentStyled>
          </WrapperSlideStyled>
        );
      },
    };
  });

  return (
    <ImageGallery
      additionalClass="example-gallery"
      showPlayButton={false}
      showBullets
      showFullscreenButton={false}
      items={images}
      showThumbnails={false}
      onBeforeSlide={(currentIndex) => {
        // закрытие слайдера, когда пользователь перещелкивает
        // последний слайд
        setIsLastSlide((prev) => {
          if (currentIndex === 0 && prev === true) {
            onClose();
          }
          return prev;
        });

        if (currentIndex === numberLastSlide) {
          setIsLastSlide(true);
        } else {
          setIsLastSlide(false);
        }
      }}
      renderLeftNav={(onClick, disabled) => <LeftNav onClick={onClick} disabled={disabled} />}
      renderRightNav={(onClick, disabled) => (
        <RightNav rightNavRef={rightNavRef} onClick={onClick} disabled={disabled} />
      )}
    />
  );
});

export { Gallery };
