import { useTranslation } from 'react-i18next';
import {
  SlideDesktop1,
  SlideDesktop2,
  SlideDesktop3,
  SlideDesktop4,
  SlideMobile1,
  SlideMobile2,
  SlideMobile3,
  SlideMobile4,
} from './assets';

const useLocale = () => {
  const { t } = useTranslation();

  return {
    SLIDES: [
      {
        slideDesktop: SlideDesktop1,
        slideMobile: SlideMobile1,
        titleText: t('onboardingSlider.sliders.slide1.title'),
        descriptionText: t('onboardingSlider.sliders.slide1.text'),
      },
      {
        slideDesktop: SlideDesktop2,
        slideMobile: SlideMobile2,
        titleText: t('onboardingSlider.sliders.slide2.title'),
        descriptionText: t('onboardingSlider.sliders.slide2.text'),
      },
      {
        slideDesktop: SlideDesktop3,
        slideMobile: SlideMobile3,
        titleText: t('onboardingSlider.sliders.slide3.title'),
        descriptionText: t('onboardingSlider.sliders.slide3.text'),
      },
      {
        slideDesktop: SlideDesktop4,
        slideMobile: SlideMobile4,
        titleText: t('onboardingSlider.sliders.slide4.title'),
        descriptionText: t('onboardingSlider.sliders.slide4.text'),
      },
    ],
  };
};

export { useLocale };
